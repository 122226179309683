import { Helmet } from 'react-helmet';

function Main(props) {
    // 컨테이너 스타일 적용
    const containerStyles = {
        width: '80%',
        height: '55vh',
        backgroundColor: 'black',
        backgroundImage: `url(${ props.videoDivImage })`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        margin: '0 auto',
        PointerEvent: 'none'
    };

    const profilePic = {
        boxShadow: props.border,
        backgroundImage: `url(${ props.profilePicImage })`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        cursor: ( props.isClickable ) ? 'pointer' : 'default'
    };

    if ( props.isFetched ) {
        return (
            <div className="liveContainer fadeIn">
                <Helmet>
                    <title>홈 :: Aringchive</title>
                </Helmet>
                <span className="namefield">{ props.userName }님은 지금 </span>
                <span className="islive">{ props.isLiveNow }</span>
                <div className="infoContainer">
                    <div className="videoDiv" style={containerStyles}>
                        <div className={ props.liveClass } title={ props.liveTitle } onClick={ ( props.isClickable ) ? () => window.open( props.chzzkLink, `_blank`) : null }>
                            <div className={ props.liveInfoClass }>
                                <img className={ props.liveProfileClass } src={ props.profilePicImage } alt={ props.userName }></img>
                                <span className={ props.liveTitleClass }>
                                    <p>{ props.title }</p>
                                    <p>
                                        { props.viewer }명과 함께&nbsp;
                                        { props.liveTime !== '' ? props.liveTime + '동안' : '' }
                                    </p>
                                    <p>{ props.game } 플레이중</p>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="streamerInfoDiv">
                        <div className="infoSection">
                            <div className="profile" style={profilePic} onClick={ ( props.isClickable ) ? () => window.open( props.chzzkLink, `_blank`) : null }></div>
                            <div className="infoTexts">
                                <div className="infoField">
                                    <span className="infoName">{ props.userName }</span>
                                    <span className="followers">팔로워
                                        <span className="followersCount">
                                        &nbsp;{ props.follower }
                                        </span>
                                    명</span>
                                </div>
                            </div>
                        </div>
                        <div className="description">
                            { props.description }
                        </div>
                        <div className="dDayCounter">
                            <span>&#x1F497;{ props.dDay }일 동안 아깽이들과 함께하는 중&#x1F497;</span>
                        </div>
                        <div className="socialLinks">
                            <div className="socialBlock discord" onClick={() => window.open( props.discordLink , '_blank')}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-discord" viewBox="0 0 16 16">
                                    <path d="M13.545 2.907a13.2 13.2 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.2 12.2 0 0 0-3.658 0 8 8 0 0 0-.412-.833.05.05 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.04.04 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032q.003.022.021.037a13.3 13.3 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019q.463-.63.818-1.329a.05.05 0 0 0-.01-.059l-.018-.011a9 9 0 0 1-1.248-.595.05.05 0 0 1-.02-.066l.015-.019q.127-.095.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.05.05 0 0 1 .053.007q.121.1.248.195a.05.05 0 0 1-.004.085 8 8 0 0 1-1.249.594.05.05 0 0 0-.03.03.05.05 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.2 13.2 0 0 0 4.001-2.02.05.05 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.03.03 0 0 0-.02-.019m-8.198 7.307c-.789 0-1.438-.724-1.438-1.612s.637-1.613 1.438-1.613c.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612m5.316 0c-.788 0-1.438-.724-1.438-1.612s.637-1.613 1.438-1.613c.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612"/>
                                </svg>
                                <span>소통하기</span>
                            </div>
                            <div className="socialBlock youtube" onClick={() => window.open( props.youtubeLink , '_blank')}>
                                <i className="bi bi-youtube"></i>
                                <span>{ props.youtube }</span>
                            </div>
                            <div className="socialBlock x" onClick={() => window.open( props.xLink , '_blank')}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-twitter-x" viewBox="0 0 16 16">
                                    <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"/>
                                </svg>
                                <span>X</span> 
                            </div>
                            <div className="socialBlock chzzk" onClick={() => window.open( props.chzzkLink , '_blank')}>
                                <svg version="1.1" x="0px" y="0px" viewBox="0 0 256 256" fill="currentColor">
                                    <path d="M242.1,189.1l-49.5-0.2l-49.5-0.3l54.5-75.2c30-41.4,54.5-75.5,54.5-75.8c0-0.3-19.8-0.5-44.1-0.5
                                        c-24.2,0-43.9-0.4-43.7-0.8c0.2-0.4,5.9-8.7,12.8-18.4l12.5-17.7h33.3l33-0.1v0H100.1l-3.4,4.2C93.8,7.9,33.4,91,28.6,98
                                        c-0.9,1.3-1.7,2.8-1.7,3.2c0,0.4,19.7,0.9,43.9,1l43.8,0.3l-55.4,76.5c-36,49.6-56.1,76.6-57.4,76.8c-1.9,0.3-1.9-1.4-1.9-121.9
                                        v121.9h242.2v-33.3V189.1z" />
                                </svg>
                                <span>치지직</span>
                            </div>
                            <div className="socialBlock twitch" onClick={() => window.open( props.cafeLink , '_blank')}>
                                <svg version="1.1" x="0px" y="0px" viewBox="0 0 256 256" fill="currentColor">
                                    <path d="M0,156.2c0,55.3,44.9,100.2,100.3,100.2c43.2,0,80.1-27.3,94.2-65.6h15.1c25.7,0,46.5-20.8,46.5-46.4
                                        c0-25.6-20.8-46.4-46.5-46.4h-9H0V156.2L0,156.2z M199.6,169.6c0.6-4.4,0.9-8.9,0.9-13.4v-37.3h9c14,0,25.4,11.4,25.4,25.4
                                        c0,14-11.4,25.4-25.4,25.4H199.6z"/>
                                    <path d="M41.9,66.8h66.8h8.6c36.9,0,66.8-29.9,66.8-66.8h-66.8h-8.6C71.8,0,41.9,29.9,41.9,66.8z"/>
                                </svg>
                                <span>팬카페</span>
                            </div>
                        </div>
                        <div className="lastUpdate">
                            <span>마지막 업데이트 : { props.lastUpdate }</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className="Modal">
                <div className="modal">
                    <span className="title">데이터를 불러옵니다...</span><br/><br/>
                    <span className="subtitle">잠시만 기다려주세요...</span>
                </div>
            </div>
        );
    }
}

export default Main;