import React, { useEffect } from 'react';
import $ from 'jquery';
import './clips.css';
import { Helmet } from 'react-helmet';

const fetchClips = () => {
    return fetch(`${window.location.origin}/api/chzzk/clips/get_popular_clips`)
        .then(response => response.json())
        .then(data => data)
        .catch(error => {
            return fetch('http://127.0.0.1:8000/api/chzzk/clips/get_popular_clips')
                .then(response => response.json())
                .then(data => data)
                .catch(error => {
                    console.error('Error:', error);
                    throw error;
                });
        });
};

function Clips() {
    useEffect(() => {
        fetchClips()
            .then((data) => {
                const popularData = data['popular']
                const topData = data['top']
                if (data['size'] === 0) {
                    console.log("표시할 데이터 없음");
                } else {
                    $('.alertContainer').remove();
                    $('.videoContainer').empty();

                    const topClipId = topData['clipUID']
                    const top = `
                        <div class="topClipCard" onclick="window.open('https://chzzk.naver.com/clips/${topClipId}', '_blank')">
                            <img src='${topData['thumbnailImageUrl']}?type=o280x500_blur' alt="thumbnail" />
                            <span class="clipTitle">${topData['clipTitle']}</span>
                        </div>`;
                    $('.clipContainer_').append(top);

                    for (let i = 0; i < popularData['size']; i++) {
                        const clipId = popularData['data'][i]['clipUID']
                        const temp = `
                            <div class="clipCard" onclick="window.open('https://chzzk.naver.com/clips/${clipId}', '_blank')">
                                <img src='${popularData['data'][i]['thumbnailImageUrl']}?type=o280x500_blur' alt="thumbnail" />
                                <span class="clipTitle">${popularData['data'][i]['clipTitle']}</span>
                            </div>`;
                        $('.clipContainer_').append(temp);
                    }
                }
            })
    }, []);

    return (
        <div className="fadeIn">
            <Helmet>
                <title>클립 :: Aringchive</title>
            </Helmet>
            <div className="clipContainer">
                <div className="title">
                    <span className="ring">Ring</span>
                    <span>Clips</span>
                    <br/>
                    <span className="title_main">인기 클립 30개를 표시합니다.</span>
                </div>
                <div className="videos">
                    <div className="alertContainer">
                        <span className="alert_title">흠.. 조용하네요.</span>
                        <span className="alert_sub">이곳이 오래 비어있다면,
                        서버 가동 시간이 1시간 미만이거나, API의 문제로
                        내용이 표시되지 않은 경우에요.</span>
                    </div>
                    <div className="clipContainer_">
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Clips;
