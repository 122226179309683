import React from 'react';
import './NotFound.css'

function NotFound() {
    return (
        <div className="fadeIn notFound">
            <div className="icon">
                <img
                    src={process.env.PUBLIC_URL + '/static/Aringsad.png'}
                    alt="aringchive" />
            </div>
            <div className="error">
                <img
                    src={process.env.PUBLIC_URL + '/static/NotFoundError.png'}
                    alt="aringchive" />
                <p>
                    요청한 페이지를 찾을 수 없어요.
                </p>
            </div>
        </div>
    );
}

export default NotFound;
