import React from 'react';
import { NavLink } from 'react-router-dom';

function Header() {
    return (
        <header>
            <div className="text">
                <span className="wrap">
                    <a href="/" >
                        <img
                            src={process.env.PUBLIC_URL + '/static/aringchive.ico'}
                            alt="aringchive" />
                    </a>
                    <span className="textSpan">
                        <span className="name">Aring</span>chive
                    </span>
                </span>
            </div>
            <div className="menu">
                <NavLink exact to="/Live" activeClassName="active">Live</NavLink>
                <NavLink exact to="/Clips">Clips</NavLink>
                <NavLink exact to="/" activeClassName="active">홈</NavLink>
                <NavLink exact to="/schedule" activeClassName="active">방송일정</NavLink>
                <NavLink exact to="/SNS/youtube" activeClassName="active">SNS</NavLink>
            </div>
        </header>
    );
}

export default Header;