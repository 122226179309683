import { NavLink, Outlet } from 'react-router-dom';
import './SNS.css';
import $ from 'jquery';

function SNS() {
    const openMenu = () => {
        $('.Sidemenu').css({ animation: 'openSmenu 0.5s forwards linear' });
        $('.screen').css({ animation: 'enableScreen 0.5s forwards linear'});
    };

    const closeMenu = () => {
        $('.Sidemenu').css({ animation: 'closeSmenu 0.5s forwards linear' });
        $('.screen').css({ animation: 'disableScreen 0.5s forwards linear'});
    };

    return (
        <div className="fadeIn">
            <div className="Sidemenu">
                <NavLink exact to="/SNS/youtube" activeClassName="active" onClick={() => closeMenu()}>
                    <i className="bi bi-youtube"></i>
                </NavLink>
                <NavLink exact to="/SNS/x" activeClassName="active" onClick={() => closeMenu()}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-twitter-x" viewBox="0 0 16 16">
                        <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"/>
                    </svg>
                </NavLink>
            </div>
            <div className="comp">
            <div className="screen" onClick={() => closeMenu()}></div>
                <i className="bi bi-caret-right-fill menuBtn" onClick={() => openMenu()}></i>
                <div className="Smenu">
                    <NavLink exact to="/SNS/youtube" activeClassName="active">
                        <i className="bi bi-youtube"></i>
                    </NavLink>
                    <NavLink exact to="/SNS/x" activeClassName="active">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-twitter-x" viewBox="0 0 16 16">
                            <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"/>
                        </svg>
                    </NavLink>
                </div>
                <Outlet></Outlet>
            </div>
        </div>
    )
}

export default SNS;